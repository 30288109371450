import type { FC } from "../lib/teact/teact";
import React, { useEffect, useLayoutEffect, useState } from "../lib/teact/teact";
import { getActions, withGlobal } from "../global";
import type { GlobalState } from "../global/types";
import type { ThemeKey } from "../types";
import type { UiLoaderPage } from "./common/UiLoader";
import {
  DARK_THEME_BG_COLOR,
  INACTIVE_MARKER,
  LIGHT_THEME_BG_COLOR,
  PAGE_TITLE,
} from "../config";
import { selectTabState, selectTheme, selectCurrentMessageList } from "../global/selectors";
import { addActiveTabChangeListener } from "../util/activeTabMonitor";
import buildClassName from "../util/buildClassName";
import { setupBeforeInstallPrompt } from "../util/installPrompt";
import { parseInitialLocationHash } from "../util/routing";
import { hasStoredSession } from "../util/sessions";
import {
  IS_INSTALL_PROMPT_SUPPORTED,
  IS_MULTITAB_SUPPORTED,
  PLATFORM_ENV,
} from "../util/windowEnvironment";
import { updateSizes } from "../util/windowSize";

import {
  storeSession,
  loadStoredSession,
  getUserAuth,
} from "src/util/sessions.ts";

import useAppLayout from "../hooks/useAppLayout";
import useFlag from "../hooks/useFlag";
import usePrevious from "../hooks/usePrevious";
import useConnectionStatus, { ConnectionStatus } from "../hooks/useConnectionStatus";
import useLang from "../hooks/useLang";
import useBrowserOnline from '../hooks/window/useBrowserOnline';

// import Test from './test/TestSvg';
import Auth from "./auth/Auth";
import UiLoader from "./common/UiLoader";
import ReconnectBar from "./common/ReconnectBar";
import AppInactive from "./main/AppInactive";
import LockScreen from "./main/LockScreen.async";
import Main from "./main/Main.async";
import Transition from "./ui/Transition";
import NewbieStep from './ui/NewbieStep';

import type { ISettings } from "../types";


import styles from "./App.module.scss";

type StateProps = {
  authState: GlobalState["authState"];
  isScreenLocked?: boolean;
  hasPasscode?: boolean;
  isInactiveAuth?: boolean;
  hasWebAuthTokenFailed?: boolean;
  theme: ThemeKey;
  connectionState: any;
  isSyncing?: boolean;
  isFetchingDifference?: boolean;
  isMessageListOpen: boolean;
  isConnectionStatusMinimized: ISettings["isConnectionStatusMinimized"];
  areChatsLoaded: boolean;
};

enum AppScreens {
  auth,
  main,
  lock,
  inactive,
}

const TRANSITION_RENDER_COUNT = Object.keys(AppScreens).length / 2;

const INACTIVE_PAGE_TITLE = `${PAGE_TITLE} ${INACTIVE_MARKER}`;

const App: FC<StateProps> = ({
  authState,
  isScreenLocked,
  hasPasscode,
  isInactiveAuth,
  hasWebAuthTokenFailed,
  theme,
  connectionState,
  isSyncing,
  isFetchingDifference,
  isMessageListOpen,
  isConnectionStatusMinimized,
  areChatsLoaded
}) => {
  const { disconnect, returnToAuthLogIn, setSettingOption, returnToAuthRegister } = getActions();

  const [isInactive, markInactive, unmarkInactive] = useFlag(false);
  const [isRegister, setIsRegister] = useState(false);
  const { isMobile } = useAppLayout();
  const lang = useLang();
  const [shouldShowReconnectBar, setShouldShowReconnectBar] = useState(false);

  const isMobileOs = PLATFORM_ENV === "iOS" || PLATFORM_ENV === "Android";

  const { connectionStatus, connectionStatusText, connectionStatusPosition } =
  useConnectionStatus(
    lang,
    connectionState,
    isSyncing || isFetchingDifference,
    isMessageListOpen,
    isConnectionStatusMinimized,
    !areChatsLoaded
  );
  const isOnline = useBrowserOnline();

  useEffect(() => {
    let timeoutId: any;

    if (!isOnline) {
      setShouldShowReconnectBar(true);
    } else if (connectionStatus === ConnectionStatus.waitingForNetwork
      || connectionStatus === ConnectionStatus.syncing
    ) {
      setShouldShowReconnectBar(false);
      // 设置 6 秒延迟显示
      timeoutId = setTimeout(() => {
        setShouldShowReconnectBar(true);
      }, 6000); // 6 秒后显示子组件
    } else {
      // 重新连接成功或其他状态时，隐藏组件并清除倒计时
      setShouldShowReconnectBar(false);
      clearTimeout(timeoutId);
    }

    return () => timeoutId && clearTimeout(timeoutId); // 清除定时器，避免内存泄漏
  }, [connectionStatus, isOnline]);

  useEffect(() => {
    if (IS_INSTALL_PROMPT_SUPPORTED) {
      setupBeforeInstallPrompt();
    }
  }, []);

  useEffect(() => {
    setSettingOption({ theme: "light" });
    setSettingOption({ shouldUseSystemTheme: false });
  }, []);

  // Prevent drop on elements that do not accept it
  useEffect(() => {
    let sessionData = loadStoredSession();
    let { id: userId } = getUserAuth() || {};
  
    if (!userId) {
      // 登陆页面
      if (localStorage.getItem('IS_REGISTER') === 'true') {
        returnToAuthRegister();
      } else {
        returnToAuthLogIn();
      }
    }

    const body = document.body;
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer) return;
      if (!(e.target as HTMLElement).dataset.dropzone) {
        e.dataTransfer.dropEffect = "none";
      } else {
        e.dataTransfer.dropEffect = "copy";
      }
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
    };
    body.addEventListener("drop", handleDrop);
    body.addEventListener("dragover", handleDrag);
    body.addEventListener("dragenter", handleDrag);

    return () => {
      body.removeEventListener("drop", handleDrop);
      body.removeEventListener("dragover", handleDrag);
      body.removeEventListener("dragenter", handleDrag);
    };
  }, []);

  useEffect(() => {
    if(authState === 'authorizationStateReady') {
      if(localStorage.getItem('IS_REGISTER') === 'true') {
        setIsRegister(true);
      }
    }
  }, [authState]);

  // return <Test />;

  //
  let activeKey: number;
  let page: UiLoaderPage | undefined;
  if (isInactive) {
    activeKey = AppScreens.inactive;
  } else if (isScreenLocked) {
    page = "lock";
    activeKey = AppScreens.lock;
  } else if (authState) {
    switch (authState) {
      // 等待手机号码授权
      case "authorizationStateWaitPhoneNumber":
        page = "authPhoneNumber";
        activeKey = AppScreens.auth;
        break;
      // 二维码授权
      case "authorizationStateWaitCode":
        page = "authCode";
        activeKey = AppScreens.auth;
        break;
      // 密码授权
      case "authorizationStateWaitPassword":
        page = "authPassword";
        activeKey = AppScreens.auth;
        break;
      //  注册授权
      case "authorizationStateWaitRegistration":
        activeKey = AppScreens.auth;
        break;
      //  注册授权
      case "authorizationStateWaitRegister":
        activeKey = AppScreens.auth;
        break;

      //  注册授权
      case "authorizationStateWaitLogIn":
        activeKey = AppScreens.auth;
        break;
      // 二维码授权
      case "authorizationStateWaitQrCode":
        page = "authQrCode";
        activeKey = AppScreens.auth;
        break;

      // 进入main主页
      case "authorizationStateClosed":
      case "authorizationStateClosing":
      case "authorizationStateLoggingOut":
      case "authorizationStateReady":
        page = "main";
        activeKey = AppScreens.main;
        break;
    }
  } else if (hasStoredSession(true)) {
    page = "main";
    activeKey = AppScreens.main;
  } else if (hasPasscode) {
    activeKey = AppScreens.lock;
  } else {
    page = isMobileOs ? "authPhoneNumber" : "authQrCode";
    activeKey = AppScreens.auth;
  }

  if (
    activeKey !== AppScreens.lock &&
    activeKey !== AppScreens.inactive &&
    activeKey !== AppScreens.main &&
    parseInitialLocationHash()?.tgWebAuthToken &&
    !hasWebAuthTokenFailed
  ) {
    page = "main";
    activeKey = AppScreens.main;
  }

  useEffect(() => {
    removeLoadingScreen();

    updateSizes();
  }, []);

  useEffect(() => {
    if (IS_MULTITAB_SUPPORTED) return;

    addActiveTabChangeListener(() => {
      disconnect();
      document.title = INACTIVE_PAGE_TITLE;

      markInactive();
    });
  }, [activeKey, disconnect, markInactive]);

  useEffect(() => {
    if (isInactiveAuth) {
      document.title = INACTIVE_PAGE_TITLE;
      markInactive();
    } else {
      document.title = PAGE_TITLE;
      unmarkInactive();
    }
  }, [isInactiveAuth, markInactive, unmarkInactive]);

  // 获取活跃key
  const prevActiveKey = usePrevious(activeKey);

  function removeLoadingScreen() {
    // const loadingScreen = document.getElementById("root-bg");
    const loadingScreen = document.getElementById("app-loading");
    if (loadingScreen) {
      // loadingScreen.remove();
      loadingScreen.style.opacity = "0"; // 添加淡出效果
      setTimeout(() => loadingScreen.remove(), 500); // 完全移除
    }
  };
  

  // eslint-disable-next-line consistent-return
  function renderContent() {
    console.log("activeKey==", activeKey);
    switch (activeKey) {
      case AppScreens.auth:
        // 授权
        return <Auth />;
      // 聊天主页面
      case AppScreens.main:
        return <Main isMobile={isMobile} />;
      //
      case AppScreens.lock:
        return <LockScreen isLocked={isScreenLocked} />;
      case AppScreens.inactive:
        return <AppInactive />;
    }
  }

  useLayoutEffect(() => {
    document.body.classList.add(styles.bg);
  }, []);

  useLayoutEffect(() => {
    document.body.style.setProperty(
      "--theme-background-color",
      LIGHT_THEME_BG_COLOR
    );
  }, [theme]);

  const closeNewbie = () => {
    setIsRegister(false);
    localStorage.setItem("IS_REGISTER", "false");
  }

  // 首页
  return (
    <UiLoader page={page} isMobile={isMobile}>
      {/* {isRegister && <NewbieStep close={closeNewbie} />} */}
      <div style={`display: ${isRegister ? "block" : "none"}`}>
        <NewbieStep close={closeNewbie} />
      </div>
      <Transition
        name="fade"
        activeKey={activeKey}
        shouldCleanup
        className={buildClassName(
          "full-height",
          (activeKey === AppScreens.auth ||
            prevActiveKey === AppScreens.auth) &&
            "is-auth"
        )}
        renderCount={TRANSITION_RENDER_COUNT}
      >
        {renderContent}
      </Transition>
      { shouldShowReconnectBar && <ReconnectBar connectionStatus={connectionStatus} isOnline={isOnline} refreshTimeout={30}/> }
      {/* { <ReconnectBar connectionStatus={connectionStatus} isOnline={isOnline} /> } */}
    </UiLoader>
  );
};

export default withGlobal((global): StateProps => {
  const { connectionState, isSyncing, isFetchingDifference, currentUserId } =
  global;
const { isConnectionStatusMinimized } = global.settings.byKey;
  return {
    authState: global.authState,
    isScreenLocked: global.passcode?.isScreenLocked,
    hasPasscode: global.passcode?.hasPasscode,
    isInactiveAuth: selectTabState(global).isInactive,
    hasWebAuthTokenFailed:
      global.hasWebAuthTokenFailed || global.hasWebAuthTokenPasswordRequired,
    theme: selectTheme(global),
    connectionState,
    isSyncing,
    isFetchingDifference,
    isMessageListOpen: Boolean(selectCurrentMessageList(global)),
    isConnectionStatusMinimized,
    areChatsLoaded: Boolean(global.chats.listIds.active),
  };
})(App);
