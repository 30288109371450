import './NewbieStep.scss';
import React, { useState, memo, useRef, useEffect } from '../../lib/teact/teact';
import type { FC } from '../../lib/teact/teact';
import { getActions, withGlobal } from "../../global";

import type { GlobalState } from "../../global/types";
import { pick } from "../../util/iteratees";
import buildClassName from "../../util/buildClassName";



import {
    selectUser,
  } from "../../global/selectors";

type StateProps = {
    currentUserId?: string;
    phoneNumber?: string;
};
type OwnProps = {
    close: () => void;
};

const NewbieStep:FC<OwnProps&StateProps> = ({
    close,
    currentUserId,
    phoneNumber
}) => {
    const imageRef = useRef<any>(null);
    const [lineHeight, setLineHeight] = useState(0);
    const [step, setStep] = useState(0);
    const [showLine, setShowLine] = useState(false);
    const addSpaces = (number: string | undefined) => {
        if(number)
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }


    useEffect(() => {
        function calculateDistance() {
            const image = imageRef.current;
            if (image) {
              const imageBottom = document.documentElement.clientHeight - image.getBoundingClientRect().bottom - 92;
              setLineHeight(imageBottom);
              setShowLine(true);
            }
          }
       
          calculateDistance();
          window.addEventListener('resize', calculateDistance);
          return () => window.removeEventListener('resize', calculateDistance);
    }, [imageRef.current?.offsetHeight])

    return(
        <div className='newbie'>
            <div className='newbie__content' id='newbieContent'>
                <div className='newbie__content__title'>
                    <h1>欢迎来到 6</h1>
                    <div className='newbie__content__title__id'>
                        <span className='id'>您的ID:</span>
                        <span className='phone'>{addSpaces(phoneNumber)}</span>
                    </div>
                </div>
                <div className='newbie__content__steps'>
                    <div className={buildClassName('newbie__content__steps__one', step === 0 ? 'show' : 'hide')}>
                        <div className='postion-relative'>
                            <img ref={imageRef} className='newbie__content__steps__one__img' src={require("../../assets/step-one.png")} alt="" />
                            {showLine && <div className='newbie__content__steps__one__line' style={`height: ${lineHeight.toString() + 'px'}`} ><i className='step-roundness'></i></div>}
                        </div>
                        <div className={buildClassName('step__btn','newbie__content__steps__one__btn')} onClick={() => setStep(1)}>下一步 1/3</div>
                        <img className='newbie__content__steps__one__affix' src={require("../../assets/step-affix.png")} alt="" />
                    </div>
                    <div className={buildClassName('newbie__content__steps__two', step === 1 ? 'show' : 'hide')}>
                        <img src={require("../../assets/step-search.png")} alt="" width={222} />
                        <div className='newbie__content__steps__two__line'><i className='step-roundness'></i></div>
                        <img className='newbie__content__steps__two__img' src={require("../../assets/step-two.png")} alt="" width={252} />
                        <div className={buildClassName('step__btn','newbie__content__steps__one__btn')} onClick={() => setStep(2)}>下一步 2/3</div>
                    </div>
                    <div className={buildClassName('newbie__content__steps__three', step === 2 ? 'show' : 'hide')}>
                        <img src={require("../../assets/step-add.png")} alt="" width={222} />
                        <img className='newbie__content__steps__two__img' src={require("../../assets/step-three.png")} alt="" width={252} />
                        <div className={buildClassName('step__btn','newbie__content__steps__one__btn')} onClick={() => close()}>完成</div>
                        <p className='newbie__content__steps__three__tip'>如果您还有疑惑 请点击查看 <span onClick={() => setStep(3)}>添加好友视频教程</span></p>
                    </div>
                    <div className={buildClassName('newbie__content__steps__four', step === 3 ? 'show' : 'hide')}>
                        <img src={require("../../assets/step-close.png")} onClick={() => close()} className='newbie__content__steps__four__close' alt="" width={27} />
                        <video id='new-video' controls poster={require('../../assets/video.png')} webkit-playsinline='true' playsInline={true} preload="none">
                            <source src="/H5.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>    
                        <p className='newbie__content__steps__three__tip'>为了更好的观看效果建议 <span>横屏播放</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(
    withGlobal((global): StateProps => {
        const { currentUserId } = global;
        const user = selectUser(global, currentUserId || "");
        return {
          currentUserId,
          phoneNumber: user?.phoneNumber,
        };
    })(NewbieStep)
  );