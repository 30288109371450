import type { FC } from "../../lib/teact/teact";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "../../lib/teact/teact";

import Button from "./Button";
import Menu from "./Menu";

import "./DropdownMenu.scss";

type OwnProps = {
  className?: string;
  trigger?: FC<{ onTrigger: () => void; isOpen?: boolean }>;
  transformOriginX?: number;
  transformOriginY?: number;
  positionX?: "left" | "right";
  positionY?: "top" | "bottom";
  footer?: string;
  forceOpen?: boolean;
  onOpen?: NoneToVoidFunction;
  onClose?: NoneToVoidFunction;
  onHide?: NoneToVoidFunction;
  onTransitionEnd?: NoneToVoidFunction;
  onMouseEnterBackdrop?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  children: React.ReactNode;
  isOpenMenu?: boolean;
  setIsOpenMenu?: Function;
};

const DropdownMenu: FC<OwnProps> = ({
  trigger,
  className,
  children,
  transformOriginX,
  transformOriginY,
  positionX = "left",
  positionY = "top",
  footer,
  forceOpen,
  onOpen,
  onClose,
  onTransitionEnd,
  onMouseEnterBackdrop,
  onHide,
  isOpenMenu = false, // 手势操作时的菜单状态
  setIsOpenMenu = () => {}, // 手势操作时的改变菜单状态方法
}) => {
  // eslint-disable-next-line no-null/no-null
  const menuRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-null/no-null
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  // 切换菜单，但是关闭菜单时不处罚，虚操作handleClose
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    setIsOpenMenu(!isOpen);
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  // 将isOpenMenu和setIsOpen的状态同步
  useLayoutEffect(() => {
    setIsOpen(isOpenMenu);
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
  }, [isOpenMenu]);

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    const menu = menuRef.current;

    if (!isOpen || e.keyCode !== 40 || !menu) {
      return;
    }

    const focusedElement = document.activeElement;
    const elementChildren = Array.from(menu.children);

    if (!focusedElement || elementChildren.indexOf(focusedElement) === -1) {
      (elementChildren[0] as HTMLElement).focus();
    }
  };
  // 关闭弹窗操作
  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsOpenMenu(false);
    onClose?.();
  }, [onClose]);

  const triggerComponent: FC<{ onTrigger: () => void; isOpen?: boolean }> =
    useMemo(() => {
      if (trigger) return trigger;

      return ({ onTrigger, isOpen: isMenuOpen }) => (
        <Button
          round
          size="smaller"
          color="translucent"
          className={isMenuOpen ? "active" : ""}
          onClick={onTrigger}
          ariaLabel="More actions"
        >
          <i className="icon icon-more" />
        </Button>
      );
    }, [trigger]);

  return (
    <div
      ref={dropdownRef}
      className={`DropdownMenu ${className || ""}`}
      onKeyDown={handleKeyDown}
      onTransitionEnd={onTransitionEnd}
    >
      {triggerComponent({ onTrigger: toggleIsOpen, isOpen })}

      <Menu
        ref={menuRef}
        containerRef={dropdownRef}
        isOpen={isOpen || Boolean(forceOpen)}
        className={className || ""}
        transformOriginX={transformOriginX}
        transformOriginY={transformOriginY}
        positionX={positionX}
        positionY={positionY}
        footer={footer}
        autoClose
        onClose={handleClose}
        shouldSkipTransition={forceOpen}
        onCloseAnimationEnd={onHide}
        onMouseEnterBackdrop={onMouseEnterBackdrop}
      >
        {children}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
