// 分割线
import React from "../../lib/teact/teact";
import type { FC } from "../../lib/teact/teact";
import "./Divider.scss"; // 引入样式文件
type OwnProps = {
  dashed?: boolean;
  orientation?: "center" | "left" | "right";
  style?: string;
  children?: React.ReactNode;
};
const Divider: FC<OwnProps> = ({
  dashed = false,
  orientation = "center",
  style,
  children,
}) => {
  return (
    <div className={`divider ${dashed ? "dashed" : ""}`} style={style}>
      {orientation === "left" && (
        <span className="divider-text">{children}</span>
      )}
      <div className="divider-line"></div>
      {orientation === "center" && (
        <span className="divider-text">{children}</span>
      )}
      <div className="divider-line"></div>
      {orientation === "right" && (
        <span className="divider-text">{children}</span>
      )}
    </div>
  );
};

export default Divider;
