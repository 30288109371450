import React, { useState, memo, useEffect } from "../../../lib/teact/teact";
import GuideDrawer from "./guide";
import { IS_IOS, IS_SAFARI } from "../../../util/windowEnvironment";
import buildClassName from "../../../util/buildClassName";
import Logo from "src/assets/logo.svg";
import InstallTips from "src/assets/icons/installApp/installTips.png";
import "./index.scss"; // 引入样式
import { selectTabState } from "../../../global/selectors";
import withInstallPrompt from "../withInstallPrompt";
import useLang from "../../../hooks/useLang";

type OwnProps = {
  handleInstallClick: () => void;
  closeDrawerHandle: () => void;
  openDrawer: Boolean;
};

const Drawer = (props: OwnProps) => {
  const { handleInstallClick, closeDrawerHandle, openDrawer } = props;
  const lang = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGuide, setIsOpenGuide] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const handleSwipe = (event: any) => {
    const { deltaY } = event;
    if (deltaY > 0) {
      setIsOpen(true); // 上滑手势展开抽屉
    } else if (deltaY < 0 && isOpen) {
      setIsOpen(false); // 下滑手势收起抽屉
    }
  };
  const addInstallHandle = (): void => {
    if (IS_IOS && IS_SAFARI) {
      setIsOpenGuide(true);
      return;
    }
    handleInstallClick();
  };

  if (!openDrawer) return;
  return (
    <div>
      <div
        className={buildClassName("drawer", isOpen && "open")}
        onWheel={handleSwipe}
      >
        <div className="drawer-trigger" onClick={toggleDrawer}>
          <div className="drawer-trigger-bar"></div>
        </div>
        <div className="drawer-content">
          <div className="drawer-content-top">
            <div className="drawer-content-top-left">
              <img className="drawer-logo" src={Logo} draggable={false} />
              <div>
                <div className="applyName">6-WEB</div>
                <div className="shortcuts">{lang("DesktopShortcut")}</div>
              </div>
            </div>
            <div className="drawer-content-top-right">
              <div className="noTips" onClick={closeDrawerHandle}>
                {lang("NoPrompts")}
              </div>
              <div className="installApp" onClick={addInstallHandle}>
                {lang("InstallDesktop")}
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="drawer-content-bottom">
              <p className="moreTips">{lang("InstallDesktopTips")}</p>
              <img
                className="installTipsImage"
                src={InstallTips}
                draggable={false}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
      {isOpenGuide && (
        <GuideDrawer isOpen={isOpenGuide} setIsOpen={setIsOpenGuide} />
      )}
    </div>
  );
};

export default memo(withInstallPrompt(Drawer));
