class PubSub {
  constructor() {
    this.handlers = {};
  }

  subscribe(event, handler) {
    this.handlers[event] = this.handlers[event] || [];
    this.handlers[event].push(handler);
  }
  unsubscribe(event, handler) {
    this.handlers[event] = [];
  }
  publish(event, data) {
    if (this.handlers[event]) {
      this.handlers[event].forEach((handler) => handler(data));
    }
  }
}

export default new PubSub();

// // 使用示例
// const pubsub = new PubSub();

// // 订阅事件
// pubsub.subscribe('message', (data) => {
//     console.log(`New message received: ${data}`);
// });

// // 发布事件
// pubsub.publish('message', 'Hello World!');
