/***
 * https://www.iconfont.cn/
 * 账号：+855967734713
 * 密码：tank3712..@/
 */
import React, { useEffect, useState } from "../../../lib/teact/teact";
import buildClassName from "../../../util/buildClassName";
import "./index.scss";

// 创建一个从 Iconfont 加载图标的函数
interface CreateFromIconfontCNOptions {
  scriptUrl: string;
}

const createFromIconfontCN = (options: CreateFromIconfontCNOptions) => {
  const { scriptUrl } = options;

  const Icon: React.FC<{
    type: string;
    style?: React.CSSProperties;
    className?: string;
  }> = ({ type, style, className, ...props }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      if (!loaded) {
        // 检查是否已经存在该 link 标签
        const existingLink = document.querySelector(
          `link[href="${scriptUrl}"]`
        );
        if (!existingLink) {
          const link = document.createElement("link");
          link.rel = "stylesheet";
          link.href = scriptUrl;
          link.onload = () => setLoaded(true);
          document.head.appendChild(link);
        } else {
          setLoaded(true);
        }
      }
    }, [loaded, scriptUrl]);

    return (
      <span
        className={buildClassName(`iconfont iconfont-${type}`, className)}
        style={style}
        {...props}
      />
    );
  };

  return Icon;
};

// 使用示例
const IconFont = createFromIconfontCN({
  // scriptUrl: "//at.alicdn.com/t/c/font_4781834_h2msobm581f.css", // 替换为你自己的图标库地址
  scriptUrl: "//at.alicdn.com/t/c/font_4781834_fqvtw8gtcnc.css", // 替换为你自己的图标库地址
});

export default IconFont;
