// 引导页面
import React, { useState, memo, useEffect } from "../../../lib/teact/teact";
import buildClassName from "../../../util/buildClassName";
import guide1 from "src/assets/icons/installApp/guide1.png";
import guide2 from "src/assets/icons/installApp/guide2.png";
import "./guide.scss"; // 引入样式

const GuideDrawer = (props: any) => {
  const { isOpen, setIsOpen } = props;

  const handleOverlayClick = () => {
    setIsOpen(false);
  };
  if (!isOpen) return null;
  return (
    <>
      <div className={buildClassName("guideDrawer", isOpen && "open")}>
        <div className="closeBtn" onClick={handleOverlayClick}>
          <i className="icon icon-close"></i>
        </div>
        <div className="guideDrawer-content">
          <div className="guideDrawer-content-tips1">
            点击“添加到主屏幕”按钮
          </div>
          <div className="guideDrawer-content-tips2">保存网页版至桌面端</div>
          <div>
            <img className="guideImage guide1" src={guide1} draggable={false} />
          </div>
          <div>
            <img className="guideImage guide2" src={guide2} draggable={false} />
          </div>
        </div>
      </div>
      <div className="overlay" onClick={handleOverlayClick}></div>
    </>
  );
};

export default GuideDrawer;
