import type { FC, TeactNode } from "../../lib/teact/teact";
import React, { memo, useCallback, useRef } from "../../lib/teact/teact";

import type { TextPart } from "../../types";

import buildClassName from "../../util/buildClassName";

import useKeyboardListNavigation from "../../hooks/useKeyboardListNavigation";
import useLang from "../../hooks/useLang";

import Button from "./Button";
import Modal from "./Modal";
import "./ConfirmDialog.scss";

type OwnProps = {
  isOpen: boolean;
  title?: string;
  header?: TeactNode;
  textParts?: TextPart;
  text?: string;
  confirmLabel?: string;
  confirmIsDestructive?: boolean;
  isConfirmDisabled?: boolean;
  isOnlyConfirm?: boolean;
  areButtonsInColumn?: boolean;
  className?: string;
  children?: React.ReactNode;
  confirmHandler: NoneToVoidFunction;
  onClose: NoneToVoidFunction;
  onCloseAnimationEnd?: NoneToVoidFunction;
};

const ConfirmDialog: FC<OwnProps> = ({
  isOpen,
  title,
  header,
  text,
  textParts,
  confirmLabel = "Confirm",
  confirmIsDestructive,
  isConfirmDisabled,
  isOnlyConfirm,
  areButtonsInColumn,
  className,
  children,
  confirmHandler,
  onClose,
  onCloseAnimationEnd,
}) => {
  const lang = useLang();

  // eslint-disable-next-line no-null/no-null
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelectWithEnter = useCallback(
    (index: number) => {
      if (index === -1) confirmHandler();
    },
    [confirmHandler]
  );

  const handleKeyDown = useKeyboardListNavigation(
    containerRef,
    isOpen,
    handleSelectWithEnter,
    ".Button"
  );

  return (
    <Modal
      className={buildClassName("confirm", className)}
      title={title || "6"}
      header={header}
      isOpen={isOpen}
      onClose={onClose}
      onCloseAnimationEnd={onCloseAnimationEnd}
    >
      <div className="modal-text">
        {text && text.split("\\n").map((textPart) => <p>{textPart}</p>)}
        {textParts || children}
      </div>
      <div
        className={
          areButtonsInColumn ? "dialog-buttons-column" : "dialog-buttons mt-2"
        }
        ref={containerRef}
        onKeyDown={handleKeyDown}
      >
        <Button
          className="confirm-dialog-button"
          isText
          onClick={confirmHandler}
          color={confirmIsDestructive ? "danger" : "primary"}
          disabled={isConfirmDisabled}
        >
          {confirmLabel}
        </Button>
        {!isOnlyConfirm && (
          <Button className="confirm-dialog-button" isText onClick={onClose}>
            {lang("Cancel")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default memo(ConfirmDialog);
