import type { LangCode } from '../../../types';

export function getSuggestedLanguage() {
  let suggestedLanguage = navigator.language.toLowerCase();
  if (suggestedLanguage === 'zh-cn') {
    suggestedLanguage = 'zh-hans-raw';
    return suggestedLanguage as LangCode;
  } else if (suggestedLanguage.substr(0, 2) === 'zh') {
    suggestedLanguage = 'zh-hant-raw';
    return suggestedLanguage as LangCode;
  }

  if (suggestedLanguage && suggestedLanguage !== 'pt-br') {
    suggestedLanguage = suggestedLanguage.substr(0, 2);
  }

  return suggestedLanguage as LangCode;
}
