import { getActions } from '../global';

let promptInstall: () => Promise<void>;

export function setupBeforeInstallPrompt() {
  // 新增逻辑-是否展示下载桌面端
  let canInstall = localStorage.getItem("canInstall");

  if(canInstall === null) {
    localStorage.setItem("canInstall", "true");
  }
  window.addEventListener('beforeinstallprompt', (e: any) => {
  // 新增end

    promptInstall = async () => {
      e.prompt();
      const userChoice = await e.userChoice;
      const isInstalled = userChoice.outcome === 'accepted';

      if (!isInstalled) return;
      getActions().setInstallPrompt({ canInstall: false });
    };
    getActions().setInstallPrompt({ canInstall: true });
  });

}

export function getPromptInstall() {
  return promptInstall;
}
