import type { FC } from "../../lib/teact/teact";
import React, {
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "../../lib/teact/teact";
import { getActions, withGlobal } from "../../global";
import type { GlobalState } from "../../global/types";
import { pick } from "../../util/iteratees";
import { IS_TOUCH_ENV } from "../../util/windowEnvironment";
import InputText from "../ui/InputText";
import Button from "../ui/Button";
import Divider from "../ui/Divider";
import Link from "../ui/Link";
import useLang from "../../hooks/useLang";
import { callApi } from "src/api/gramjs";
import {
  invokeRequest,
  fetchCurrentUser,
} from "src/api/gramjs/methods/client.ts";
import { USER_NAME_KEY } from "../../util/constants";
import useLastCallback from "../../hooks/useLastCallback";
import IconFont from "../common/IconFont";
import useAppLayout from '../../hooks/useAppLayout';
import { setCookie } from './cookie';

// import {
//   getDifference,

//   init as initUpdatesManager,
//   processUpdate,
//   reset as resetUpdatesManager,
//   scheduleGetChannelDifference,
//   updateChannelState,
// } from 'src/api/gramjs/updates/updateManager.ts';

// import {
//   onAuthError, onAuthReady, onCurrentUserUpdate, onRequestCode, onRequestPassword, onRequestPhoneNumber,
//   onRequestQrCode, onRequestRegistration, onWebAuthTokenFailed,
// } from 'src/api/gramjs/methods/auth.ts';

import styles from "./Register.module.scss";

type StateProps = Pick<
  GlobalState,
  "authPhoneNumber" | "authIsCodeViaApp" | "authIsLoading" | "authError"
>;

const Register: FC<StateProps> = ({
  authPhoneNumber,
  authIsCodeViaApp,
  authIsLoading,
  authError,
  connectionState,
}) => {
  const {
    returnToAuthLogIn,
    apiUpdate: onUpdate,
    provideAccountPassword,
    clearAuthError
  } = getActions();

  const [accountNum, setAccountNum] = useState<string>("");
  const [isLogining, setIsLogining] = useState(false);

  // form数据
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  // 表单错误提示
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const lang = useLang();
  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  const onPassWordChange = (e: any) => {
    const { currentTarget: target } = e;
    // 禁止非法字符
    const regex = /[^\w!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
    target.value = target.value.replace(regex, "")
    setFormData({
      ...formData,
      password: target.value,
    });
    setError({
      ...error,
      password: "",
    });
  };
  const onConfirmPassWordChange = (e: any) => {
    const { currentTarget: target } = e;
    // 禁止非法字符
    const regex = /[^\w!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
    target.value = target.value.replace(regex, "")
    setFormData({
      ...formData,
      confirmPassword: target.value,
    });
    setError({
      ...error,
      confirmPassword: "",
    });
  };

  const habdleReturnToAuthLogIn = useCallback(() => {
    returnToAuthLogIn();
  }, [returnToAuthLogIn]);

  // 获取uuid
  const getUuid = async () => {
    const uuid = await callApi("getUUid");
    if (uuid) {
      setAccountNum(uuid);
    } else {
      getUuid();
    }
  };
  useEffect(() => {
    getUuid();
    localStorage.setItem("IS_REGISTER", "true");
  }, []);

  useEffect(() => {
    if (isLogining && !!authError) {
      setIsLogining(false);
    }
  }, [authError]);

  const handleOk = async () => {
    const { password, confirmPassword } = formData;
    // 校验密码长度
    if (password.length < 8) {
      setError({ ...error, password: lang("密码长度至少为8位字符") });
      return;
    }
    // 校验密码和确认密码是否一致
    if (password !== confirmPassword) {
      setError({ ...error, confirmPassword: lang("两次密码不一致") });
      return;
    }

    clearAuthError();
    setIsLogining(true);

    localStorage.setItem(USER_NAME_KEY, accountNum);
    setCookie(USER_NAME_KEY, accountNum);

    provideAccountPassword({
      type: "registerLogin",
      account: accountNum,
      password,
    });
  };

  const { isMobile } = useAppLayout();
  const handleRefresh = useLastCallback(() => {
    window.location.reload();
  });

  return (
    <div className={styles.RegistrationPage}>
      <div className={styles.IconWrapper} >
        <Button
          round
          size="smaller"
          ripple={!isMobile}
          color="translucent"
          onClick={handleRefresh}
          ariaLabel="refresh"
        >
          <IconFont type="refresh" />
        </Button>
      </div>
      <div className={styles.pageTitle}>{lang("注册账号")}</div>
      <div className={styles.pageTips}>
        {lang("密码应包含至少8个数字或字母")}
      </div>

      <div>
        <InputText
          ref={inputRef}
          id="accountNumInput"
          label={lang("账户")}
          disabled
          // onInput={onAccountNumChange}
          value={accountNum}
          error={authError && lang(authError)}
          autoComplete="off"
          inputMode="numeric"
        />
        {/* <div className="login-loading-box">
          <div className={`Loading interactive  login-loading`}>
            <div className="Spinner yellow with-background bg-light">
              <div className="Spinner__inner"></div>
            </div>
          </div>
        </div> */}
        <input
          type="password"
          id="prevent_autofill"
          autoComplete="off"
          className="visually-hidden"
          tabIndex={-2}
        />
        <input
          type="password"
          id="prevent_autofill2"
          autoComplete="off"
          className="visually-hidden"
          tabIndex={-2}
        />
      </div>

      <InputText
        ref={inputRef}
        id="passWordInput"
        type="password"
        label={lang("密码")}
        compositionInput={true}
        onInput={onPassWordChange}
        value={formData.password}
        error={error.password}
        autoComplete="off"
      />
      <InputText
        ref={inputRef}
        id="confirmPassWordInput"
        type="password"
        label={lang("重复确认")}
        compositionInput={true}
        onInput={onConfirmPassWordChange}
        value={formData.confirmPassword}
        error={error.confirmPassword}
        autoComplete="off"
      />
      <Button
        isLoading={isLogining}
        loadingText={lang("正在注册")}
        onClick={handleOk}
        className={styles.closeButton}
        disabled={isLogining || connectionState != "connectionStateReady"}
      >
        {lang("注册并登录")}
      </Button>

      {connectionState != "connectionStateReady" ? (
        <div className="login-loading-box">
          <span className="login-loading-text">
            {lang("网络正在连接，请稍后")}
          </span>
          <div className={`Loading interactive  login-loading`}>
            <div className="Spinner yellow with-background bg-light">
              <div className="Spinner__inner"></div>
            </div>
          </div>
        </div>
      ) : null}

      <Divider style="margin: 1.5rem 0;">or</Divider>
      <div className={styles.loginTips}>
        <span>{lang("已有账号？")}</span>
        <Link isPrimary onClick={habdleReturnToAuthLogIn}>
          {lang("去登录")}
        </Link>
      </div>
    </div>
  );
};
export default memo(
  withGlobal(
    (global): StateProps =>
      pick(global, [
        "authPhoneNumber",
        "authIsCodeViaApp",
        "authIsLoading",
        "authError",
        "connectionState",
      ])
  )(Register)
);
