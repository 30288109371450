import { Fragment } from "react";
import React, {
  // memo,
  // useCallback,
  useEffect,
  useRef,
  useState,
} from "../../../lib/teact/teact";
import type { FC } from "../../../lib/teact/teact";
import { ConnectionStatus } from "../../../hooks/useConnectionStatus";

import Loading from '../../ui/Loading';
import './style.scss'; // 引入独立的样式文件

type StateProps = {
  connectionStatus: any;
  isOnline: boolean;
  refreshTimeout: number;
};

const ReconnectBar: FC<StateProps> = ({
  connectionStatus,
  isOnline,
  refreshTimeout = 30
}) => {
  const [countdown, setCountdown] = useState(refreshTimeout); // 倒计时
  const [dots, setDots] = useState('');

  // 倒计时和自动刷新逻辑
  useEffect(() => {
    let intervalId: any;
    if (isOnline) {
      intervalId = setInterval(() => {
        setCountdown((prev) => Math.max(prev - 1, 0));
      }, 1000);
    }

    const needRefresh = connectionStatus === ConnectionStatus.waitingForNetwork 
      || connectionStatus === ConnectionStatus.syncing;
    if (countdown === 0 && needRefresh) {
      window.location.reload();
    }

    return () => intervalId && clearInterval(intervalId);
  }, [countdown, connectionStatus, isOnline]);

  // 动态点的动画逻辑
  useEffect(() => {
    const dotsInterval = setInterval(() => {
      setDots((prev) => {
        if (prev.length >= 3) {
          return ''; // 重置为无点
        }
        return prev + '.'; // 每次增加一个点
      });
    }, 500); // 每 500ms 增加一个点

    return () => clearInterval(dotsInterval); // 清除计时器
  }, []);

  const handleRefresh = () => {
    setCountdown(30);
    window.location.reload();
  };

  const renderOnline = () => {
    return (
      <div className={`reconnect-bar online}`}>
        <div className="row">
          {/* <Loading color="yellow" backgroundColor="light" /> */}
          <span>{connectionStatus === ConnectionStatus.syncing ? '数据更新中' : '重连中'}</span>
          <span>{dots}</span>
        </div>
        <div className="row">
          <span>页面将在 {countdown} 秒后刷新</span>
          <button onClick={handleRefresh} className="reconnect-btn">立即刷新</button>
        </div>
      </div>
    );
  }

  const renderOffline = () => {
    return (
      <div className={`reconnect-bar offline`}>
        <span>设备已离线</span>
      </div>
    );
  }

  return isOnline ? renderOnline() : renderOffline();
  // return renderOnline();
};

export default ReconnectBar;
