import "./util/handleError";
import "./util/setupServiceWorker";
import "./global/init";

import React from "./lib/teact/teact";
import TeactDOM from "./lib/teact/teact-dom";
import { getActions, getGlobal } from "./global";
import { reconnect, disconnect, setIsOnLine } from "./api/gramjs";
import {
  DEBUG,
  MULTITAB_LOCALSTORAGE_KEY,
  STRICTERDOM_ENABLED,
  HELLO9_URLS,
} from "./config";
import { enableStrict, requestMutation } from "./lib/fasterdom/fasterdom";
import { selectTabState } from "./global/selectors";
import { betterView } from "./util/betterView";
import {
  establishMultitabRole,
  subscribeToMasterChange,
} from "./util/establishMultitabRole";
import {
  requestGlobal,
  subscribeToMultitabBroadcastChannel,
} from "./util/multitab";
import { checkAndAssignPermanentWebVersion } from "./util/permanentWebVersion";
import { onBeforeUnload } from "./util/schedulers";
import updateWebmanifest from "./util/updateWebmanifest";
import { IS_MULTITAB_SUPPORTED } from "./util/windowEnvironment";

import App from "./components/App";
import XMLHttpRequest from "src/lib/XMLHttpRequest.js";
import Loading from "src/components/ui/Loading.tsx";
import "./assets/fonts/roboto.css";
import "./styles/index.scss";
import { INVITE_CODE_KEY } from "./util/constants";

const { HTTP_URL, SOCKET_URL, WEB_ENV } = process.env;
if (STRICTERDOM_ENABLED) {
  enableStrict();
}

const getAddress = async () => {
  let address = localStorage.getItem("address");

  // if (!address) {
  address = await new Promise((reslove, reject) => {
    new XMLHttpRequest().xhRequest({
      url: HTTP_URL || '/url.json',
      method: "GET",
      success: async (res: any) => {
        const { backupDomains: list } = res;
        let $data = list.map((item: any) => {
          return item.indexOf(':') > -1 ? item : `${item}:443`;
        });

        localStorage.setItem("address",JSON.stringify($data));
        reslove($data);
      },
      error: (error:any) => {
        localStorage.setItem("address", JSON.stringify([SOCKET_URL]));
        reslove(error);
      },
    });
  });
  // } else {
  //   address = (JSON.parse(address))
  // }
  return address;
};

const REFRESH_TIME = 600;
// const RECONNECT_TIME = 60;
const RECONNECT_TIME = 180;
const RECONNECT_DIRECT_TIME = 20;
const RECONNECT_WAIT_TIME = 0; // 毫秒
const MAX_RECONNECT_COUNT = 3; // 最大重连次数

let isOnline = true;

window.addEventListener('online', handleOnLine);
window.addEventListener('offline', handleOffLine);

function handleOnLine() {
  console.log("网络连接已恢复");
  isOnline = true;
  setIsOnLine(true);
  reconnect(RECONNECT_WAIT_TIME, MAX_RECONNECT_COUNT, false, false);
}

function handleOffLine() {
  console.log("网络连接已断开");
  isOnline = false;
  setIsOnLine(false);
  disconnect();
}


const checkVisiblityChange = () => {
  document.addEventListener("visibilitychange", function () {
    let hiddenTime;

    if (document.visibilityState == "hidden") {
      //记录页面隐藏时间
      hiddenTime = new Date().getTime();
      localStorage.setItem("hiddenTime", hiddenTime.toString());
      console.log("页面隐藏：", hiddenTime);
    } else {
      hiddenTime = hiddenTime || Number(localStorage.getItem("hiddenTime"));
      let visibleTime = new Date().getTime();

      // if((visibleTime - hiddenTime) / 1000 > REFRESH_TIME){	 // 页面再次可见的时间-隐藏时间 > 180S, 刷新页面
      //     window.location.reload();
      // } else
      if ((visibleTime - hiddenTime) / 1000 > RECONNECT_TIME) {
        // 页面再次可见的时间-隐藏时间 > 20S, 断开重连
        try {
          console.log(`页面退到后台超过${RECONNECT_TIME}s，触发重连`);
          // init();
          isOnline && reconnect(RECONNECT_WAIT_TIME, MAX_RECONNECT_COUNT, true, false);
        } catch (error) {
          // window.location.reload();
        }
      } else if ((visibleTime - hiddenTime) / 1000 > RECONNECT_DIRECT_TIME) {
        // 页面再次可见的时间-隐藏时间 > 10S, 重连
        try {
          console.log(`页面退到后台超过${RECONNECT_TIME}s，触发重连`);
          // init();
          isOnline && reconnect(RECONNECT_WAIT_TIME, MAX_RECONNECT_COUNT, false, false);
        } catch (error) {
          // window.location.reload();
        }
      } else {
        console.log("还没有到断开的时间");
      }
    }
  });
};

function extractInviteCodeFromPath() {
  // 获取路径部分
  const path = window.location.pathname;

  // 使用正则表达式检查是否为一级路径且纯数字
  const regex = /^\/(\d+)$/;
  const match = path.match(regex);

  if (match) {
    // 提取数字部分
    const extractedNumber = match[1];
    return extractedNumber;
  }

  // 不符合条件则返回 null
  return null;
}

function extractAndSaveInviteCode() {
  const inviteCode = extractInviteCodeFromPath();
  if (inviteCode) {
    console.log("extract invite code: ", inviteCode);
    localStorage.setItem(INVITE_CODE_KEY, inviteCode);
  }
}

async function init() {
  await getAddress();

  if (!(window as any).isCompatTestPassed) return;

  checkVisiblityChange();

  checkAndAssignPermanentWebVersion();

  extractAndSaveInviteCode();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();

    await requestGlobal(APP_VERSION);

    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, "1");

    onBeforeUnload(() => {
      const global = getGlobal();

      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();

  // 初始化
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  if (IS_MULTITAB_SUPPORTED) {
    establishMultitabRole();
    subscribeToMasterChange((isMasterTab) => {
      //1
      getActions().switchMultitabRole(
        { isMasterTab },
        { forceSyncOnIOs: true }
      );
    });
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> START INITIAL RENDER");
  }

  // 渲染
  requestMutation(() => {
    updateWebmanifest();

    setTimeout(() => {
      TeactDOM.render(<App />, document.getElementById("root")!);
    }, 1000);

    // TeactDOM.render(<App />, document.getElementById("root")!);
    // removeLoadingScreen();

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> FINISH INITIAL RENDER");
  }

  if (DEBUG) {
    document.addEventListener("dblclick", () => {
      // eslint-disable-next-line no-console
      console.warn("TAB STATE", selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn("GLOBAL STATE", getGlobal());
    });
  }

  window.addEventListener(
    "message",
    function (event) {
      // 检查消息来源
      if (
        HELLO9_URLS.includes(event.origin)
      ) {
        // 处理消息
        console.log("@@@@@@@@@@@", event.data);
        const { user_id, message } = event.data;
        if (user_id) {
          getActions().acceptContactWithoutAdd({
            id: String(user_id),
            message: message,
          });
          event.source?.postMessage({
            user_id: user_id,
            status: "success",
          });
        }
      }
    },
    false
  );
}

init();

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
