import React, { useState, useEffect } from "../../../lib/teact/teact";
import ConfirmDialog from "../../ui/ConfirmDialog";
import useLang from "../../../hooks/useLang";

// 高阶组件
const withInstallPrompt = (WrappedComponent: any) => {
  return (props: any) => {
    const lang = useLang();
    let canInstall = localStorage.getItem("canInstall"); // 获取localStorage的canInstall值
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [isInstalling, setIsInstalling] = useState(false); // 添加 loading 状态
    const [openDrawer, setOpenDrawer] = useState(false); // 抽屉展示
    const [showDialog, setShowDialog] = useState(false); // 弹窗提示

    useEffect(() => {
      const handleBeforeInstallPrompt = (e: any) => {
        e.preventDefault();
        setDeferredPrompt(e);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      // 清理事件监听器
      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }, []);

    // 监听是否需要打开抽屉
    useEffect(() => {
      setOpenDrawer(canInstall === "true");
    }, [canInstall]);

    const handleInstallClick = async () => {
      if (!deferredPrompt) {
        setShowDialog(true);
        return;
      }
      setOpenDrawer(false);
      setIsInstalling(true);
      try {
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === "accepted") {
          console.log("用户接受了安装提示");
        } else {
          // 如果拒绝安装，则继续打开安装抽屉
          // if (!openDrawer) {
          localStorage.setItem("canInstall", "true");
          setOpenDrawer(true);
          // }
          console.log("用户拒绝了安装提示");
        }
      } catch (error) {
        console.log("错误:", error);
      } finally {
        setDeferredPrompt(null);
        setIsInstalling(false);
      }
    };

    const closeDrawerHandle = () => {
      localStorage.setItem("canInstall", "false");
      setShowDialog(false);
      setOpenDrawer(false);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          deferredPrompt={deferredPrompt}
          isInstalling={isInstalling}
          handleInstallClick={handleInstallClick}
          closeDrawerHandle={closeDrawerHandle}
          openDrawer={openDrawer}
        ></WrappedComponent>
        <ConfirmDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          title={lang("modal.title")}
          text={lang("AddedToDesktop")}
          isOnlyConfirm
          confirmHandler={closeDrawerHandle}
          confirmLabel={lang("OK")}
        />
      </>
    );
  };
};

export default withInstallPrompt;
